import React, { useState, useEffect } from "react";
import CountUp from "react-countup";

// material UI
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

// data
import { Products_data, Overview_data } from "../../../Data";

// components
import ProductSelector from "../../Components/ProductSelector";

// charts
import LineChart from "../../Components/charts/LineChart";
import API from "../../../Controller/API";

const Overview = (props) => {
  const [CurrentProduct, setCurrentProduct] = useState(Products_data["0"]);

  const getSitemapStatus = (d) => {
    return d.success / d.total;
  };

  const testRequest = () => {

    API.get("/api/v1/auth/users")
      .then((resp) => {
        console.log(resp);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className="dashOverview_container">
      <h1 className="dashPageTitle">Overview</h1>

      {/* top cards */}
      <div className="topCardContainer">
        <div className="card">
          <h3 className="title">Products</h3>
          <h2 className="value">
            <CountUp end={Products_data.length} />
          </h2>
        </div>
        <div className="card">
          <h3 className="title">Users</h3>
          <h2 className="value">
            <CountUp end={6} />
          </h2>
        </div>
        <div className="card">
          <h3 className="title">Down alerts</h3>
          <h2 className="value">
            <CountUp end={3} />
          </h2>
        </div>
        <div className="card">
          <h3 className="title">Password health</h3>
          <h2 className="value">
            <CountUp end={90} />%
          </h2>
        </div>
        <div className="card">
          <h3 className="title">Threat alerts</h3>
          <h2 className="value">
            <CountUp end={3} />
          </h2>
        </div>
      </div>

      <hr />

      <div className="selctChild_wrapper">
        <ProductSelector
          data={Products_data}
          setProduct={setCurrentProduct}
          currentProduct={CurrentProduct.id}
        />
      </div>

      <Grid container spacing={1}>
        <Grid container spacing={1} item sm={6}>
          <Grid item sm={4}>
            <div className="chartBox statusCard">
              <h3 className="title">HTTP status</h3>
              <div className="content centered">
                <h1
                  className={
                    Overview_data[CurrentProduct.id].http_status === 200
                      ? "statusText"
                      : "statusText error"
                  }
                >
                  {Overview_data[CurrentProduct.id].http_status}
                </h1>
              </div>
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className="chartBox statusCard">
              <h3 className="title">HTTP latency</h3>
              <div className="content centered">
                <h1
                  className={
                    Overview_data[CurrentProduct.id].http_latency <= 1
                      ? "statusText"
                      : Overview_data[CurrentProduct.id].http_latency <= 2
                      ? "statusText warning"
                      : "statusText error"
                  }
                >
                  {Overview_data[CurrentProduct.id].http_latency}
                </h1>
              </div>
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className="chartBox statusCard">
              <h3 className="title">Sitemap status</h3>
              <div className="content centered">
                <h1
                  className={
                    getSitemapStatus(
                      Overview_data[CurrentProduct.id].sitemap_status
                    ) >= 1
                      ? "statusText"
                      : getSitemapStatus(
                          Overview_data[CurrentProduct.id].sitemap_status
                        ) >= 0.5
                      ? "statusText warning"
                      : "statusText error"
                  }
                >
                  {Overview_data[CurrentProduct.id].sitemap_status.success}/
                  {Overview_data[CurrentProduct.id].sitemap_status.total}
                </h1>
              </div>
            </div>
          </Grid>
          <Grid item sm={4}>
            <div className="chartBox statusCard">
              <h3 className="title">Robots.txt status</h3>
              <div className="content centered">
                <h1
                  className={
                    Overview_data[CurrentProduct.id].robots_status === 200
                      ? "statusText"
                      : "statusText error"
                  }
                >
                  {Overview_data[CurrentProduct.id].robots_status}
                </h1>
              </div>
            </div>
          </Grid>
          <Grid item sm={8}>
            <div className="chartBox statusCard">
              <h3 className="title">Robots.txt content</h3>
              <div className="content centered">
                <div className="chipBox">
                  {Overview_data[CurrentProduct.id].robots_content.allow.map(
                    (d) => {
                      return <Chip className="chip allow" label={d} />;
                    }
                  )}
                  {Overview_data[CurrentProduct.id].robots_content.Disallow.map(
                    (d) => {
                      return <Chip className="chip Disallow" label={d} />;
                    }
                  )}
                  {Overview_data[CurrentProduct.id].robots_content.Sitemap.map(
                    (d) => {
                      return <Chip className="chip Sitemap" label={d} />;
                    }
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <div className="chartBox statusCard">
            <h3 className="title">Latency over time</h3>
            <div className="content">
              <LineChart data={Overview_data[CurrentProduct.id].latency_time} />
            </div>
          </div>
        </Grid>
      </Grid>

    </div>
  );
};

export default Overview;
