import React, { useState } from "react";
import { useSelector } from "react-redux";

const UserCard = (props) => {
  const Permissions_state = useSelector((state) => state.permissions);

  const [User, setUser] = useState(props.data);

  return (
    <div
      onClick={(e) => props.setCUser({ ...User })}
      className={
        User.user_id === props.cUser
          ? "userCard_container active"
          : "userCard_container"
      }
    >
      <p>{`${User.first_name || ""} ${User.last_name || ""}`}</p>
      <p className="email">{`${User.username || ""}`}</p>
      <p className="permissions">
        {User.user_permissions.map((p, i) => {
          return (
            <i
              key={i}
              className={
                Permissions_state[p.toString()]
                  ? Permissions_state[p.toString()].icon
                  : ""
              }
            ></i>
          );
        })}
      </p>
    </div>
  );
};

export default UserCard;
