import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// images
import logo from "../Images/logo.svg";
import Aveter from "../Images/userPlaceholder.png";

const Header = (props) => {
  const user_state = useSelector((state) => state.user);

  return (
    <div className="dashHeader_wrapper">
      <div className="logoBlock">
        <img src={logo} alt="" srcSet="" />
      </div>

      <div className="menuBlock">
        <img src={user_state.profilePic} alt="" srcSet="" />
        <i className="fas fa-bars"></i>
      </div>
    </div>
  );
};

export default Header;
