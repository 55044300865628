import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import Dash_header from "./Components/Dash_header";
import Dash_Sidebar from "./Components/Dash_sidebar";

// pages
import Overview from "./Pages/Dashboard/Overview";
import Users from "./Pages/Dashboard/Users";
import Settings from "./Pages/Dashboard/Settings";

const Dashboard = (props) => {
  const match = useRouteMatch();

  const [SidebarOpen, setSidebarOpen] = useState(true);

  const ToggleSidebar = () => {
    setSidebarOpen((st) => !st);
  };

  return (
    <div className="Dashboard_wrapper">
      <Dash_Sidebar openState={SidebarOpen} ToggleSidebar={ToggleSidebar} />
      <Dash_header />
      <div
        className={
          SidebarOpen ? "Dashboard_container sb_open" : "Dashboard_container sb_close"
        }
      >
        <Switch>
          <Route
            path={`${match.url}/overview`}
            render={(props) => <Overview {...props} />}
          />
          <Route
            path={`${match.url}/users`}
            render={(props) => <Users {...props} />}
          />
          <Route
            path={`${match.url}/settings`}
            render={(props) => <Settings {...props} />}
          />
          <Route
            path={`${match.url}`}
            render={(props) => <Redirect to={`${match.url}/overview`} />}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Dashboard;
