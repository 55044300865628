import { user_signin, user_signout } from "../Actions/types";

// controllers
import C_User from "../Controller/User";

const initial_state = C_User.isAuthenticated()
  ? {
      isSignedin: true,
      token: C_User.getToken(),
      email: C_User.getEmail(),
      name: C_User.getName(),
      profilePic: C_User.getProfilePic(),
      permissions : C_User.getPermission()
    }
  : {
      isSignedin: false,
      token: "",
      email: "",
      name: "",
      profilePic: "",
      permissions : []
    };

const user = (state = initial_state, action) => {
  switch (action.type) {
    // ===============================================
    // =============== Sign In Reducer ===============
    // ===============================================
    case user_signin:
      return {
        isSignedin: true,
        token: C_User.getToken(),
        email: C_User.getEmail(),
        name: C_User.getName(),
        profilePic: C_User.getProfilePic(),
        permissions : C_User.getPermission()
      };

    // ================================================
    // =============== Sign Out Reducer ===============
    // ================================================
    case user_signout:
      return {
        isSignedin: false,
        token: "",
        email: "",
        name: "",
        profilePic: "",
        permissions : []
      };
    default:
      return state;
  }
};

export default user;
