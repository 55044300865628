// API
import API from "./API";

class UserManagement {
  constructor() {
    this.api = {
      getAllUsers: "/api/v1/auth/users",
      addPermissionToUser: "/api/v1/permissions/add_permissions",
    };
  }

  getAllUsers(onSuccess, onError) {
    API.get(this.api.getAllUsers)
      .then((resp) => {
        if (resp.data.success) {
          onSuccess(resp.data);
        } else {
          onError(resp.data);
        }
      })
      .catch((err) => {
        console.error(err);
        onError(err);
      });
  }

  addPermissionToUser(userID, PermissionList, onSuccess, onError) {

    var FD = new FormData();

    FD.append("user_id", userID);
    FD.append("permissions_id", PermissionList);

    API.post(this.api.addPermissionToUser, FD)
      .then((resp) => {
        if (resp.data.success) {
          onSuccess(resp.data);
        } else {
          onError(resp.data);
        }
      })
      .catch((err) => {
        console.error(err);
        onError(err);
      });
  }
}

const obj = new UserManagement();

export default obj;
