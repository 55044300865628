// import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// controller
import C_User from "./Controller/User";

const ProtectedRoute = ({ component: Component, ...rest }) => {

  var user_state = useSelector(state => state.user);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user_state.isSignedin) {
          return <Component {...rest} {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
