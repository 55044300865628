import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

// material UI
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// config
import Config from "../../../Controller/Config";

const Overview = (props) => {
  const [Host, setHost] = useState(Config.host);

  const setBaseUrl = () => {
    Cookies.set("bURL", Host);
    window.location.reload();
  };

  return (
    <div className="dashSettings_container">
      <h1 className="dashPageTitle">Settings</h1>

      <Grid container spacing={1}>
        <Grid item sm={4}>
          <div className="chartBox">
            <h3 className="title">Custom Backend URL</h3>
            <div className="backendURL">
              <TextField
                onChange={(e) => setHost(e.target.value)}
                className="input"
                label={"Host Url"}
                value={Host}
              />
              <Button onClick={setBaseUrl} className="button">
                Set Backend URL
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview;
