import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";

const LineChart = (props) => {

  var labels = props.data ? props.data.dates : [];
  var series = props.data
    ? Object.keys(props.data.data).map((k) => {
        return {
          type: "line",

          smooth: true,
          name: k,
          data: props.data.data[k],
          itemStyle: { borderWidth: 0 },
        };
      })
    : [];
  return (
    <div className="LineChart_container">
      <ReactEcharts
        theme="shine"
        width="100%"
        option={{
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },
          xAxis: {
            type: "category",
            data: labels,
            axisLabel: {
              color: "#1da1f2",
              rotate: 45,
              interval: 0,
            },
          },
          yAxis: {
            type: "value",
            axisLine: {
              show: true,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              color: "#1da1f2",
            },
          },
          tooltip: {
            show: true,
            backgroundColor: "#000000",
            borderWidth: 0,
            textStyle: {
              color: "#1da1f2",
            },
          },
          series: series,
        }}
      />
    </div>
  );
};

export default LineChart;
