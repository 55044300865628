import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";

// controllers
import C_User from "../../Controller/User";
import Config from "../../Controller/Config";

// images

const Sidebar = (props) => {
  const match = useRouteMatch();

  const user_state = useSelector((state) => state.user);
  const Permissions_state = useSelector((state) => state.permissions);

  const handleSignOut = (e) => {
    C_User.signOut(() => {
      Config.showAlert("Something went wrong", "Oops");
    });
  };

  return (
    <div
      className={
        props.openState
          ? "adminSidebar_wrapper open"
          : "adminSidebar_wrapper close"
      }
    >
      <div className="Header">
        <i
          onClick={() => props.ToggleSidebar()}
          className="toggleIcon fas fa-align-right"
        ></i>
      </div>

      <div className="nav">
        <div className="linkGroup">
          <NavLink to={`${match.url}/overview`}>
            <i className="fas fa-chart-line"></i> Overview
          </NavLink>

          {user_state.permissions.map((p) => {
            if (Permissions_state[p.id] && Permissions_state[p.id].sidebar) {
              return (
                <NavLink to={`${match.url}/${Permissions_state[p.id].path}`}>
                  <i className={`${Permissions_state[p.id].icon}`}></i>{" "}
                  {Permissions_state[p.id].title}
                </NavLink>
              );
            } else {
              return null;
            }
          })}

          {/* <hr />

          <NavLink to={`${match.url}/systemstatus`}>
            <i className="fas fa-check-double"></i> System status
          </NavLink>

          <NavLink to={`${match.url}/analytics`}>
            <i className="fas fa-chart-pie"></i> Analytics
          </NavLink>
          <NavLink to={`${match.url}/cache`}>
            <i className="fas fa-database"></i> Cache management
          </NavLink>
          <NavLink to={`${match.url}/passwords`}>
            <i className="fas fa-key"></i> Password manager
          </NavLink>
          <NavLink to={`${match.url}/users`}>
            <i className="fas fa-users"></i> Users
          </NavLink>
          <NavLink to={`${match.url}/inventory`}>
            <i className="fas fa-boxes"></i> Inventory
          </NavLink> */}
        </div>
        <div className="linkGroup">
          <NavLink to={`${match.url}/settings`}>
            <i className="fas fa-cogs"></i> Settings
          </NavLink>
          <button onClick={handleSignOut}>
            <i className="fas fa-sign-out-alt"></i> Sign Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
