import React, { useState, useEffect, useRef } from "react";
import Particles from "react-tsparticles";
import GoogleLogin from "react-google-login";
import { useHistory } from "react-router-dom";

// redux
import { useSelector, useDispatch } from "react-redux";
import { signin } from "../Actions/user";
import { setPermissions } from "../Actions/permissions";
import { setLoading } from "../Actions/loading";

// controllers
import C_User from "../Controller/User";
import Config from "../Controller/Config";

// images
import logo from "./Images/logo.svg";
import google_logo from "./Images/google-logo.png";
import reactDom from "react-dom";

const Home = (props) => {
  const history = useHistory();

  // redux dispatcher
  const dispatcher = useDispatch();

  const loginSuccess = (r) => {
    dispatcher(setLoading(true));
    console.log("google Data : ", r);

    const googleProfileData = {
      token: r.tokenId,
      fName: r.profileObj.givenName,
      lName: r.profileObj.familyName,
      email: r.profileObj.email,
      profilePic: r.profileObj.imageUrl,
    };

    C_User.signIn(
      googleProfileData,

      // Success
      (r) => {
        dispatcher(signin());

        Config.setPermissionList((d) => {
          dispatcher(setPermissions(d));
        });

        dispatcher(setLoading(false));
        history.push("/dashboard");
      },

      // faliure
      (e) => {
        dispatcher(setLoading(false));
        Config.showAlert(e.data, "Oops");
      }
    );
  };

  return (
    <div className="homeWrapper">
      <Particles
        params={{
          particles: {
            shape: { type: "circle" },
            color: "#fbaf1a",
            size: {
              random: false,
              value: 3,
            },
            number: {
              value: 70,
            },
            opacity: {
              value: 1,
            },
            move: {
              direction: "top",
              enable: true,
              outMode: "out",
              random: true,
              speed: 1,
              straight: true,
            },
            links: {
              enable: true,
              color: "#007dc4",
              distance: 175,
              frequency: 100,
            },
          },
        }}
        style={{
          width: "100%",
          // backgroundImage: `url(${logo})`,
        }}
      />
      <img src={logo} alt="" srcSet="" />
      <h1>Dev-Ops Administration Dashboard</h1>
      <GoogleLogin
        clientId="808594453164-jprc5fbd7eb95qn7miot4kd8spd8m852.apps.googleusercontent.com"
        // buttonText="Login with your iLabs Google Account"
        render={(renderProps) => (
          <button
            className="googleLogin"
            onClick={(e) => {
              dispatcher(setLoading(true));
              renderProps.onClick(e);
            }}
            disabled={renderProps.disabled}
          >
            <img src={google_logo} alt="" srcset="" />
            Login with iLabs G-Suite account
          </button>
        )}
        onSuccess={loginSuccess}
        onFailure={(r) => {
          dispatcher(setLoading(false));
          Config.showAlert(r.error, "Oops");
        }}
        cookiePolicy={"single_host_origin"}
      />
      ,
    </div>
  );
};

export default Home;
