import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material UI
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

import * as echarts from "echarts";

// redux Actions
import { setPermissions } from "./Actions/permissions";

// Pages
import Home from "./View/Home";
import Dashboard from "./View/Dashboard";

// styles
import "./App.css";
import "./View/Styles/main.scss";

// Components
import ProtectedRoute from "./ProtectedRoute";
import Loading from "./View/Components/LoadingScreen";

// controllers
import Config from "./Controller/Config";

// echarts themes
// import shine from "./View/Styles/shine";

// echarts.registerTheme('shine', shine);

function App() {
  const dispatch = useDispatch();

  const User_state = useSelector((state) => state.user);

  const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: { main: "#1da1f2" },
    },
  });

  useEffect(() => {
    if (User_state.isSignedin) {
      Config.setPermissionList((d) => {
        dispatch(setPermissions(d));
      });
      
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Loading />
        <Router>
          {/* <Route exact strict render={(props) => <Header transparent={["/"].includes(props.location.pathname)} {...props} />} /> */}
          <Switch>
            <Route
              exact
              strict
              path="/"
              render={(props) => <Home {...props} />}
            />
            <ProtectedRoute
              // exact
              strict
              path="/dashboard"
              // render={(props) => <Dashboard {...props} />}
              component={Dashboard}
            />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
