import React, { useState } from "react";
import Switch from "@material-ui/core/Switch";

const UserPermissionCard = (props) => {
  // data from Props
  const Permission = props.Premission;
  const CurrentUser = props.CurrentUser;
  const hp = CurrentUser.user_permissions.indexOf(Permission.id) >= 0;

  return (
    <div className="permissionCard">
      <i className={Permission.icon}></i>
      <span className="name">{Permission.name}</span>
      <Switch
        checked={hp}
        onChange={(e) =>
          props.handlePermission(Permission.id, e.target.checked)
        }
        color="primary"
        name="checkedB"
        disabled={!Permission.editable}
      />
    </div>
  );
};

export default UserPermissionCard;
