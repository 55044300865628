import { combineReducers } from "redux";
import user from "./user";
import loading from "./loading";
import permissions from "./permissions";

const reducers = combineReducers({
  user: user,
  loading: loading,
  permissions: permissions,
});

export default reducers;
