import { confirmAlert } from "react-confirm-alert";
import Cookies from "js-cookie";

import API from "./API";

import { Permissions_data } from "../Data";

class Config {
  constructor() {
    this.host =
      Cookies.get("bURL") ||
      process.env.REACT_APP_BACKEND_BASE_URL ||
      "http://qa-twitter-be-v2.incubatelabs.com/api/v1";
    this.port = "";
  }

  async showAlert(_msg, _title) {
    await confirmAlert({
      title: _title || "Alert",
      message: _msg,
      buttons: [
        {
          label: "Ok",
        },
      ],
    });

    return 0;
  }

  async showAlertWC(_msg, _title, callback, cancelCallback) {
    await confirmAlert({
      title: _title || "Alert",
      message: _msg,
      buttons: [
        {
          label: "Ok",
          onClick: () => callback(),
        },
        {
          label: "Cancel",
          onClick: cancelCallback ? () => cancelCallback() : null,
        },
      ],
    });

    return 0;
  }

  getLocalTime(UTC) {
    const _utc = UTC + " UTC";

    var date = new Date(_utc);
    return date.toLocaleString();
  }

  async setPermissionList(onSuccess) {
    await API.get("/api/v1/permissions/all")
      .then((resp) => {
        if (resp.data.success) {
          var permissionList = {};
          resp.data.data.map((d) => {
            permissionList[d.id.toString()] = {
              ...Permissions_data[d.name],
              ...d,
            };
          });
          onSuccess(permissionList)
        }
      })
      .catch((err) => console.log(err));
  }
}

const obj = new Config();

export default obj;
