import { loading_set} from "../Actions/types";

const initial_state = false;

const loading = (state = initial_state, action) => {
  switch (action.type) {
    // ==================================================
    // =============== Sset loading state ===============
    // ==================================================
    case loading_set:
      return action.data;

    default:
      return state;
  }
};

export default loading;
