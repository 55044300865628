import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// redux actions
import { setLoading } from "../../../Actions/loading";

// material UI
import Grid from "@material-ui/core/Grid";

// components
import UserCard from "../../Components/UserCard";
import UserPermissionCard from "../../Components/UserPermissionCard";

// Controllers
import C_UserManagement from "../../../Controller/UserManagement";
import Config from "../../../Controller/Config";

// images
import userImage from "../../Images/userPlaceholder.png";

const Users = (props) => {
  const dispatch = useDispatch();

  const Permissions_state = useSelector((state) => state.permissions);

  const [UserList, setUserList] = useState([]);
  const [CurrentUser, setCurrentUser] = useState(null);
  const [DataChanged, setDataChanged] = useState(false);

  // ===============================================
  // ================ Get User List ================
  // ===============================================
  const getUserList = () => {
    dispatch(setLoading(true));

    C_UserManagement.getAllUsers(
      (data) => {
        setUserList(data.data.page);
        dispatch(setLoading(false));
      },
      () => {
        dispatch(setLoading(false));
      }
    );
  };

  // =============================================
  // ================ Select user ================
  // =============================================
  const selectUser = (usr) => {
    if (DataChanged) {
      Config.showAlertWC(
        "You have unsaved changes. Are sure dou you want exit without saving",
        "Alert",
        () => {
          setDataChanged(false);
          setCurrentUser(usr);
        }
      );
    } else {
      setCurrentUser(usr);
    }
  };

  const handlePermission = async (id, permissionState) => {
    var usr = {...CurrentUser}; 

    console.log("Input", id, permissionState);
    await console.log("Before change : ", usr.user_permissions);

    setDataChanged(true);

    await dispatch(setLoading(true));

    if (permissionState) {
      await usr.user_permissions.push(id);
      await setCurrentUser(usr);
    } else {
      await usr.user_permissions.splice(usr.user_permissions.indexOf(id), 1);
      await setCurrentUser(usr);
    }
    await console.log("after change : ", usr.user_permissions);
    await dispatch(setLoading(false));
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <div className="dashUsers_container">
      <h1 className="dashPageTitle">Users</h1>

      <Grid container spacing={1}>
        {/* ========================================= */}
        {/* =============== User List =============== */}
        {/* ========================================= */}
        <Grid item sm={CurrentUser ? 6 : 12}>
          <div className="chartBox">
            <h3 className="title">User List</h3>
            <div className="userList">
              {UserList.map((d, i) => {
                return (
                  <UserCard
                    setCUser={(u) => selectUser(u)}
                    cUser={CurrentUser ? CurrentUser.user_id : 0}
                    data={d}
                    key={i}
                  />
                );
              })}
            </div>
          </div>
        </Grid>

        {CurrentUser ? (
          <Grid item sm={6}>
            {/* ============================================ */}
            {/* =============== User Details =============== */}
            {/* ============================================ */}
            <div className="chartBox userDetails_contsiner">
              <h3 className="title">User Details</h3>
              <div className="content">
                <p className="userDetails">
                  <span className="key">User ID : </span> {CurrentUser.user_id}{" "}
                  <br />
                  <span className="key">First name : </span>{" "}
                  {CurrentUser.first_name} <br />
                  <span className="key">Last name : </span>{" "}
                  {CurrentUser.last_name} <br />
                  <span className="key">Email : </span> {CurrentUser.username}{" "}
                  <br />
                  <span className="key">Last login : </span>{" "}
                  {CurrentUser.last_login} <br />
                </p>

                <img src={CurrentUser.imageURL || userImage} alt="" />
              </div>
            </div>

            {/* ========================================= */}
            {/* =============== User List =============== */}
            {/* ========================================= */}
            <div className="chartBox userPermission_contsiner">
              <h3 className="title">User Permissions</h3>

              <div className="content">
                {Object.keys(Permissions_state).map((d, i) => {
                  return (
                    <UserPermissionCard
                      Premission={Permissions_state[d]}
                      CurrentUser={CurrentUser}
                      handlePermission={handlePermission}
                      key={i}
                    />
                  );
                })}
              </div>
              <div className="content"></div>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default Users;
