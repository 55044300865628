import { user_signin, user_signout } from "./types";

export const signin = () => {
  return {
    type: user_signin,
  };
};

export const signout = () => {
  return {
    type: user_signout,
  };
};
