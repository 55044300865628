import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useSelector } from "react-redux";

const Loading = (props) => {
  const loadingState = useSelector((state) => state.loading);

  if (loadingState) {
    return (
      <div className="loadingScreen_container">
        <CircularProgress size={100} />
      </div>
    );
  } else {
    return null;
  }
};

export default Loading;
