import Cookies from "js-cookie";
// import qs from "query-string";

//jwt
import JWT from "jsonwebtoken";


// API
import API from "./API";

// config
// import Config from "./Config";

class User {
  constructor() {
    this.api = {
      signIn: "/api/v1/auth/login",
      signOut: "/api/v1/auth/logout",
    };
  }

  signIn(data, onSuccess, onFaliure) {
    const req_body = {
      token: data.token || "",
      is_social: true,
      remember: 1,
      email: data.email,
      mobile_number: "",
      first_name: data.fName,
      last_name: data.lName,
      imageURL : data.profilePic,
    };

    API.post(this.api.signIn, req_body)
      .then((resp) => {
        console.log(resp);
        console.log(JWT.decode(resp.data.data.token, { complete: true }));
        if (resp.data.success) {
          this.setCookies(
            resp.data.data.token,
            data.fName + data.lName,
            data.email,
            data.profilePic,
            resp.data.data.permissions,
            true
          );
          onSuccess(resp.data);
        } else {
          onFaliure(resp.data);
        }
      })
      .catch((err) => {
        console.error(err);
        onFaliure(err);
      });
  }

  signOut(onFaliure) {
    const req_body = {};

    API.post(this.api.signOut, req_body)
      .then((resp) => {
        if (resp.data.success) {
          this.forceSignOut();
        } else {
          onFaliure(resp.data);
        }
      })
      .catch((err) => {
        console.error(err);
        onFaliure(err);
      });
  }

  isAuthenticated() {
    // return true;
    if (
      Cookies.get("cN") === undefined ||
      Cookies.get("cM") === undefined ||
      Cookies.get("cTok") === undefined
    ) {
      return false;
    } else {
      return true;
    }
  }

  setCookies(token, name, email, profilePic, permissions, persist) {
    var secureState = false;

    if (persist) {
      Cookies.set("cN", btoa(name), { expires: 30, secure: secureState });
      Cookies.set("cM", btoa(email), { expires: 30, secure: secureState });
      Cookies.set("cP", btoa(profilePic), { expires: 30, secure: secureState });
      Cookies.set("cTok", token, { expires: 30, secure: secureState });
      Cookies.set("cPer", btoa(JSON.stringify(permissions)), {
        expires: 30,
        secure: secureState,
      });
    } else {
      Cookies.set("cN", btoa(name), { secure: secureState });
      Cookies.set("cM", btoa(email), { secure: secureState });
      Cookies.set("cP", btoa(profilePic), { secure: secureState });
      Cookies.set("cTok", token, { secure: secureState });
      Cookies.set("cPer", btoa(JSON.stringify(permissions)), {
        secure: secureState,
      });
    }
  }

  getToken() {
    return Cookies.get("cTok");
  }

  getName() {
    var name;
    try {
      name = atob(Cookies.get("cN"));
    } catch (error) {
      name = "N/A";
    }
    return name;
  }

  getPermission() {
    var name;
    try {
      name = JSON.parse(atob(Cookies.get("cPer")));
    } catch (error) {
      name = [];
    }
    return name;
  }

  getEmail() {
    var email;
    try {
      email = atob(Cookies.get("cM"));
    } catch (error) {
      email = "N/A";
    }
    return email;
  }

  getProfilePic() {
    var pp;
    try {
      pp = atob(Cookies.get("cP"));
    } catch (error) {
      pp = "";
    }
    return pp;
  }

  forceSignOut() {
    Cookies.remove("cN");
    Cookies.remove("cM");
    Cookies.remove("cTok");
    Cookies.remove("cP");
    Cookies.remove("cPer");

    window.location.replace("/");
  }
}

const obj = new User();

export default obj;
