import axios from "axios";
import Cookies from "js-cookie";

// Controllers
import C_User from "./User";
import Config from "./Config";

const token = Cookies.get("cTok") || "";
// const token = C_User.getToken() || "";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`,
  headers: {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});


API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // C_User.forceSignOut();
      // C_User.getToken();

      Config.showAlert(
        "You are not allowed to do this operation, Please contact Admin",
        "Stop"
      );
    }
    throw error;
  }
);

export default API;
