import React from "react";

const ProductSelector = (props) => {
  return (
    <div className="ProductSelector">
      {props.data.map((d) => {
        return (
          <div
            onClick={() => props.setProduct(d)}
            className={props.currentProduct === d.id ? "child active" : "child"}
          >
            <img src={d.image_url} alt="" />

            <span className="name">{d.name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ProductSelector;
