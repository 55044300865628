import { permissions_set } from "../Actions/types";

import API from "../Controller/API";

var initial_state = [];

const loading = (state = initial_state, action) => {
  switch (action.type) {
    // ==================================================
    // =============== Sset loading state ===============
    // ==================================================
    case permissions_set:
      return action.data;

    default:
      return state;
  }
};

export default loading;
